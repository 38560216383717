<template>
<!-- 班课选择 -->
  <div id="app" style="background: #f4f4f4">
<!--    <div class="harken-ban">-->
<!--      <el-carousel :height="autoHeight" style="width: 100%">-->
<!--        <el-carousel-item v-for="(item, index) in banners" :key="index">-->
<!--          <a :href="'#' + item.href" target="_blank">-->
<!--            <img :src="item.imageUrl" class="bans" />-->
<!--          </a>-->
<!--        </el-carousel-item>-->
<!--      </el-carousel>-->
<!--    </div>-->
    <div class="guide-demo">
      <img class="icon1" src="@/assets/img/choose-guide-icon1.png" />
      <div class="cson-container curson">
        <div class="title">
          <h2>北美CS求职必修课</h2>
          <p>北美计算机通识课 + 免费资源，提前了解北美留学求职的一切</p>
        </div>
        <div class="curson-con">
          <div class="curson-content clearfix" style="">
            <div
                v-for="(item, ii) in cursons"
                :key="ii"
                class="box"
                @click="courseClick(item)"
                v-if="item.id == 11 || item.id == 13"
            >
              <!-- <img
                v-if="item.tag.value == '1'"
                :src="item.rightImg"
                class="cg-float-img"
              />
              <img
                v-if="item.tag.value == '2'"
                :src="item.rightImg"
                class="cg-float-img"
              />
              <img
                v-if="item.tag.value == '3'"
                :src="item.rightImg"
                class="cg-float-img"
              /> -->
              <img :src="item.cover" />
              <div
                  style="
                  padding-left: 12px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 32px;
                "
              >
                {{ item.className }}
              </div>
              <p style="color: rgba(0, 0, 0, 0.55)">{{ item.brief }}</p>
              <!-- <span
                v-if="item.discountPrice && item.discountPrice > 0"
                class="countprice"
                >优惠</span
              > -->
              <!-- <span
                v-if="item.discountPrice && item.discountPrice > 0"
                class="discount"
              >
                {{ `$${item.usddiscountPrice.toFixed()}` }}
              </span>
              <span
                :class="[
                  'baseprice',
                  item.discountPrice && item.discountPrice > 0 ? 'deleted' : '',
                ]"
              >
                {{ `$${item.usdprice && item.usdprice.toFixed()}` }}
              </span>
              <span class="buynow">立即购买</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="cson-container curson">
        <div class="title">
          <h2>北美CS求职课程</h2>
          <p>以面试为核心的体系课程，从基础到实战，助你一路拿到offer</p>
        </div>
        <div class="curson-con">
          <div class="curson-content clearfix" style="">
            <div
                v-for="(item, ii) in cursons"
                :key="ii"
                class="box"
                @click="courseClick(item)"
                v-if="item.id != 7 && item.id != 9 && item.id != 12 && item.id != 11 && item.id != 13"
            >
              <img :src="item.cover" />
              <div
                  style="
                  padding-left: 12px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 32px;
                "
              >
                {{ item.className }}
              </div>
              <p style="color: rgba(0, 0, 0, 0.55)">{{ item.brief }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="cson-container curson">
        <div class="title">
          <h2>1v1北美求职旗舰班</h2>
          <p>所有北美CS求职课程体系，工业级项目，内推监督，零基础到拿到offer</p>
        </div>
        <div class="curson-con">
          <div class="curson-content clearfix" style="">
            <div
                v-for="(item, ii) in cursons"
                :key="ii"
                class="box"
                @click="courseClick(item)"
				v-if="item.id == 7 || item.id == 9 || item.id == 12"
            >
              <img :src="item.cover" />
              <div
                  style="
                  padding-left: 12px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 32px;
                "
              >
                {{ item.className }}
              </div>
              <p style="color: rgba(0, 0, 0, 0.55)">{{ item.brief }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="cson-container curson">
        <div class="title">
          <h2>1v1私教</h2>
          <p>针对北美面试为主的私教服务，各种类型模拟面试一应俱全</p>
        </div>
        <div class="curson-con">
          <div class="curson-content clearfix" style="">
            <div
              v-for="(item, ii) in mocks"
              :key="ii"
              class="box"
              @click="mockClick(item)"
            >
              <img :src="item.cover" />
              <div
                style="
                  padding-left: 12px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 32px;
                "
              >
                {{ item.courseName }}
              </div>
              <p style="color: rgba(0, 0, 0, 0.55)">{{ item.brief }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="guide-con">-->
    <!--      <img class="icon4" src="@/assets/img/choose-guide-icon4.png">-->
    <!--      <div class="top-bg"></div>-->
    <!--      <div class="bottom-bg"></div>-->
    <!--      <div class="guide-content">-->
    <!--        <div class="title">选课指南</div>-->
    <!--      </div>-->
    <!--      -->
    <!--    </div>-->
  </div>
</template>

<script>
import { getAllClasses, getCourseBanner } from "@/service";
import { getAllMockCourses } from "@/service/mock";
export default {
  data() {
    return {
      banners: [],
      cursons: [],
      mocks: [],
    };
  },
  mounted() {
    this._getAllClasses();
    this.getAllMockCourses();
    this.getBanner();
  },
  methods: {
    _getAllClasses() {
      getAllClasses()
        .then((res) => {
          console.log(res);
          if (!res.result.code) {
            this.cursons = res.result;
			console.log("222222222222222222")
			console.log(res.result)
			const listsss  =  res.result.filter ( item => item.id != 7 && item.id != 9)
			console.log(listsss)
          }
        })
        .catch((err) => {});
    },
    getAllMockCourses() {
      getAllMockCourses().then((res) => {
        if (res.success) {
          console.log(res);
          this.mocks = res.result;
        }
      });
    },
    courseClick(item) {
      this.$router.push(`/course/${item.id}`);
    },
    mockClick(item) {
      this.$router.push(`/mock/${item.id}`);
    },
    getBanner() {
      getCourseBanner(1)
        .then((res) => {
          console.log(res);
          if (!res.result.code) {
            console.log(res.result);
            this.banners = res.result;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toDetail(objectId) {
      // if (objectId == 8 || objectId == 6) {
      //   this.$message.closeAll();
      //   this.$message.warning("该课程暂未开放，敬请期待！");
      //   return false;
      // }
      this.$router.push(`/course/${objectId}`);
    },
  },
  computed: {
    autoHeight() {
      let _w =
        document.documentElement.clientWidth || document.body.clientWidth;
      return (_w * 400) / 1920 + "px";
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/guide.css";
/* 1111 */
img {
  width: 100%;
  vertical-align: top;
}
.guid-info {
  height: 236px;
}

.countprice {
  border: 1px solid #e02020;
  color: #e02020;
  padding: 2px 4px;
  border-radius: 2px;
  background: rgba(224, 32, 32, 0.1);
  margin-left: 12px;
  margin-right: 10px;
}

.discount {
  font-weight: bold;
  font-size: 14px;
  color: #e02020;
  line-height: 28px;
}

.baseprice {
  font-size: 14px;
  color: #e02020;
  font-weight: bold;
  margin-left: 12px;
  margin-right: 10px;
  line-height: 28px;
}

.baseprice.deleted {
  font-weight: inherit;
  color: rgba(0, 0, 0, 0.4);
  text-decoration: line-through;
}

.buynow {
  border-radius: 14px;
  background: #fa6400;
  color: #fff;
  padding: 0px 8px;
  float: right;
  height: 28px;
  line-height: 28px;
  margin-right: 12px;
}

.btn-info {
  padding: 0;
}
</style>
